<template>
  <div class="home">
    <div>
      <template>
        <v-card>
          <v-card-title>Dashboard</v-card-title>
          <p v-if="loading" style="width: 100%; text-align: center;">
            <v-icon>fas fa-spinner fa-spin</v-icon> Carregando gráfico de
            entradas/saídas ...
          </p>
          <v-expansion-panels>
            <v-expansion-panel v-for="(rpG, ixRpg) in groupStats" :key="ixRpg">
              <v-expansion-panel-header
                :style="rpG['full'] >= 80 ? 'color: red;' : 'color: green;'"
                >{{ rpG['groupNAME'] }} -
                {{ rpG['full'] }}%</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <h3>Informações dos grupos ativos:</h3>
                <p>Capacidade: {{ rpG['capacity'] }}</p>
                <p>Participantes: {{ rpG['users'] }}</p>
                <p>Livre: {{ rpG['free'] }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>
      <template>
        <v-card light>
          <v-card-title>Entradas / Saídas</v-card-title>
          <p
            v-if="!rptDataGroupAmountDifLog.loaded"
            style="width: 100%; text-align: center;"
          >
            <v-icon>fas fa-spinner fa-spin</v-icon> Carregando gráfico de
            entradas/saídas ...
          </p>
          <evasion-graph
            v-else-if="rptDataGroupAmountDifLog.evasionGraph"
            :items="rptDataGroupAmountDifLog.evasionGraph.items"
            :groups="rptDataGroupAmountDifLog.evasionGraph.groups"
          />
          <h3 v-else>Nenhuma informação disponível</h3>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import EvasionGraph from './../components/reports/EvasionGraph'
export default {
  name: 'home',
  components: {
    'evasion-graph': EvasionGraph
  },
  data() {
    return {
      loading: true,
      groupStats: [],
      rptDataGroupAmountDifLog: {
        loaded: false,
        evasionGraph: null
      },
      gettingData: false
    }
  },
  mounted() {},
  created() {
    var vm = this
    vm.loading = true
    vm.getMainPage()
    vm.loadRptGroupAmountDifLog()
  },
  methods: {
    getMainPage() {
      var vm = this
      this.getRpt('rptMyGroupStats')
        .then(_gs => {
          vm.groupStats = _gs
        })
        .catch(() => {
          setTimeout(() => {
            vm.getMainPage()
          }, 5000)
        })
    },
    getRpt(_name, _data) {
      var vm = this
      return new Promise((resolve, reject) => {
        vm.$API
          .Request('post', `report/${_name}`, _data)
          .then(result => {
            resolve(result.data)
            vm.loading = false
          })
          .catch(error => {
            vm.searching = false
            console.debug(error)
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (error.response.data.code == 200) {
                vm.$snotify.success(error.response.data.message)
              } else {
                vm.$snotify.error(error.response.data.message)
              }
            }
            reject(error)
          })
      })
    },
    loadRptGroupAmountDifLog() {
      var vm = this
      vm.rptDataGroupAmountDifLog.loaded = false
      if (!vm.gettingData) {
        if (vm.loading) {
          setTimeout(() => {
            vm.loadRptGroupAmountDifLog()
          }, 500)
          return
        }
        vm.gettingData = true
        vm.getRpt('rptGroupAmountDifLog', {
          // groupID: vm.groupStats[index].groupID
        })
          .then(result => {
            vm.gettingData = false
            let _evasion = { items: [], groups: [] }
            let _tempGroups = {}
            result.forEach(item => {
              let _itemLabem = `${
                item['dif'] > 0 ? `+${item['dif']}` : item['dif']
              }`
              _evasion.items.push({
                x: item['date'],
                y: item['dif'],
                group: item['group_id'],
                label: {
                  content: _itemLabem
                }
              })
              if (!_tempGroups[item['group_id']]) {
                _tempGroups[item['group_id']] = {
                  id: item['group_id'],
                  content: item['group_name']
                }
                _evasion.groups.push(_tempGroups[item['group_id']])
              }
            })
            vm.rptDataGroupAmountDifLog.evasionGraph = _evasion
            vm.rptDataGroupAmountDifLog.loaded = true
            console.debug('EVASION DATA', _evasion)
          })
          .catch(() => {
            vm.gettingData = false
            setTimeout(() => {
              vm.loadRptGroupAmountDifLog()
            }, 5000)
          })
      }
    }
  }
}
</script>
