<template>
  <div>
    <Graph2d
      ref="graph2d"
      :items="items"
      :groups="groups"
      :options="options"
      :events="['rangechanged']"
      @rangechanged="rangeChanged"
    />
  </div>
</template>
<style lang="sass">
@import './../../../node_modules/vue2vis/dist/vue2vis.css'
</style>
<script>
import { Graph2d } from 'vue2vis'
export default {
  props: {
    items: Array,
    groups: Array
  },
  name: 'EvasionGraph',
  components: {
    Graph2d
  },
  data() {
    return {
      options: {
        style: 'bar',
        stack: false,
        barChart: {
          width: 200,
          align: 'center',
          sideBySide: true,
          minWidth: 100
        }, // align: left, center, right
        drawPoints: {
          enabled: true
        },
        legend: {
          enabled: true
        },
        dataAxis: {
          left: {
            title: {
              text: 'Entrada / Saída'
            }
          }
        },
        orientation: 'top',
        format: {
          minorLabels: {
            millisecond: 'SSS',
            second: 's',
            minute: 'HH:mm',
            hour: 'HH:mm',
            weekday: 'DD/MM',
            day: 'D',
            month: 'MMM',
            year: 'YYYY'
          },
          majorLabels: {
            millisecond: 'DD/MM/YYYY HH:mm:ss',
            second: 'DD/MM/YYYY HH:mm',
            minute: 'DD/MM/YYYY HH:mm',
            hour: 'DD/MM/YYYY',
            weekday: 'DD/MM/YYYY',
            day: 'DD/MM/YYYY',
            month: 'DD/MM/YYYY',
            year: 'DD/MM/YYYY'
          }
        }
      }
    }
  },
  mounted() {},
  methods: {
    rangeChanged(_obj1) {
      if (_obj1.byUser) {
        this.$refs.graph2d.redraw()
      }
    }
  }
}
</script>
